import PropTypes from 'prop-types'

import { useBodyClassName } from 'hooks/dom'

import './BodyClassName.sass'

export const BodyClassName = props => {
  const { className } = props
  useBodyClassName(className)

  return null
}
BodyClassName.propTypes = {
  className: PropTypes.string
}
export default BodyClassName
