import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTransition, animated } from 'react-spring'

import { block } from 'utils/classnames'

import './MultiStepPanel.sass'

const b = block.with('multi-step-panel')

export const MultiStepPanel = (props) => {
  const {
    children,
    active,
    reverse = false,
    className = ''
  } = props

  const isActive = useRef(false)
  useEffect(() => {
    isActive.current = true
  }, [])

  const ActiveStep = useMemo(
    () => {
      if (Array.isArray(children)) {
        return children.find(child => child?.props?.id === active)
      }
      return children || null
    },
    [active, children]
  )

  const options = useMemo(() => (
    {
      from: {
        transform: !reverse
          ? 'translate3d(100%,0,0)'
          : 'translate3d(-100%,0,0)'
      },
      enter: {
        transform: 'translate3d(0%,0,0)'
      },
      leave: {
        transform: !reverse
          ? 'translate3d(-100%,0,0)'
          : 'translate3d(100%,0,0)'
      },
      immediate: !isActive.current,
      config: { duration: 400 }

    }
  ), [reverse, isActive.current])
  const getKey = useCallback(p => p?.props?.id, [])

  const transitions = useTransition(
    ActiveStep,
    getKey,
    options
  )

  return (
    <div className={`${b()} ${className || ''}`.trim()}>
      {
        transitions.map(({ item, props, key }) => (
          <animated.div
            className={b('step')}
            style={props}
            key={key}
          >
            {item}
          </animated.div>
        ))
      }
    </div>
  )
}
MultiStepPanel.propTypes = {
  children: PropTypes.node,
  active: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  className: PropTypes.string
}
export default MultiStepPanel
