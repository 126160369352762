import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTransition, animated } from 'react-spring'

import { block } from 'utils/classnames'

import BodyClassName from './BodyClassName'

import './AnimatedOverlay.sass'

const b = block.with('animated-overlay')

const fadeInTransition = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 }
}

const getScrollbarWidth = () => {
  if (typeof window === 'undefined') {
    return 0
  }
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer)

  return scrollbarWidth
}

export const AnimatedOverlay = props => {
  const {
    shouldShow = false,
    noScroll = true,
    onClick,
    className = ''
  } = props

  const transitions = useTransition(shouldShow, null, fadeInTransition)

  const scrollBarWidth = useMemo(() => {
    return getScrollbarWidth()
  }, [shouldShow])

  return transitions
    .map(({ item, key, props }) => (
      item
        ? (
          <animated.div
            key={key}
            className={`${b()} ${className}`.trim()}
            style={props}
            onClick={onClick}
          >
            {
              shouldShow && noScroll
                ? (
                  <BodyClassName
                    className={`scroll-bar-width-${scrollBarWidth}`}
                  />
                )
                : null
            }
          </animated.div>
        )
        : null
    ))
}
AnimatedOverlay.propTypes = {
  shouldShow: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  noScroll: PropTypes.bool
}
export default AnimatedOverlay
