import React, { useState, useEffect, useRef } from 'react'
import dynamic from 'next/dynamic'
import MultiStepPanel from 'components/Common/Panel/MultiStepPanel'
import { getOrderDetails, getOrderTrackingData } from 'actions/orderDetails'
import { block } from 'utils/classnames'

import './OrderSummaryPanel.sass'

const b = block.with('order-details-summary')

const OrderDetailsPanel = dynamic(() => import('components/Order/OrderDetails/OrderDetailsPanel'))
const CancelPanel = dynamic(() => import('components/Order/OrderCancel/CancelPanel'))
const ConfirmCancel = dynamic(() => import('components/Order/OrderCancel/ConfirmCancel'))
const OrderTrackingPanel = dynamic(() => import('components/Order/OrderTracking/OrderTrackingPanel'))

const params = {
  details: {
    step: 'details',
    sort: 0
  },
  cancel: {
    step: 'cancel',
    sort: 1
  },
  confirm: {
    step: 'confirm',
    sort: 2
  },
  tracking: {
    step: 'tracking',
    sort: 3
  }
}

const OrderSummaryPanel = ({ onClose, orderHash }) => {
  const [step, setStep] = useState(params.details.step)
  const [order, setOrder] = useState()
  const [tracking, setTracking] = useState()
  const [time, setTime] = useState(Date.now())

  useEffect(() => {
    const getOrder = async () => {
      const order = await getOrderDetails(orderHash)
      setOrder(order)
    }
    getOrder()
    const interval = setInterval(() => setTime(Date.now()), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const getTracking = async () => {
      const tracking = await getOrderTrackingData(orderHash)
      setTracking(tracking)
    }
    getTracking()
  }, [time])

  const goToCancel = () => {
    setStep(params.cancel.step)
  }
  const goToConfirm = () => {
    setStep(params.confirm.step)
  }
  const goToDetails = () => {
    setStep(params.details.step)
  }
  const goToTracking = () => {
    setStep(params.tracking.step)
  }

  const lastIndex = useRef(0)

  const reverse = React.useMemo(() => {
    const last = lastIndex.current
    lastIndex.current = params[step].sort

    return last > params[step].sort
  }, [step])

  return (
    <MultiStepPanel
      active={step}
      className={b('', { step })}
      reverse={reverse}
    >
      <OrderDetailsPanel
        id={params.details.step}
        onBack={onClose}
        onCancelOrder={goToCancel}
        order={order}
        onDisplayTracking={goToTracking}
        tracking={tracking}
      />
      <OrderTrackingPanel
        id={params.tracking.step}
        onBack={goToDetails}
        tracking={tracking}
      />
      <CancelPanel
        id={params.cancel.step}
        onBack={goToDetails}
        onNext={goToConfirm}
        order={order}
      />
      <ConfirmCancel
        id={params.confirm.step}
        onBack={onClose}
        order={order}
      />
    </MultiStepPanel>
  )
}

export default OrderSummaryPanel
