import PropTypes from 'prop-types'
import React from 'react'

import { withRouter } from 'components/Router'

import Panel from 'components/Common/Panel'

const PanelComponentPropTypes = {
  shouldShow: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object,
  panelClassName: PropTypes.string
}

export const RoutePanel = props => {
  const {
    path,
    Component,
    position,
    productId = null
  } = props

  if (!path || !Component) {
    return null
  }

  const PanelComponent = React.useMemo(
    () => withRouter(path)(
      ({
        shouldShow,
        onClose,
        query,
        panelClassName,
        ...rest
      }) => (
        <Panel
          shouldShow={shouldShow}
          onClose={onClose}
          className={panelClassName}
          position={position}
          {...props}
        >
          <Component productId={productId} onClose={onClose} {...query} {...rest} />
        </Panel>
      )
    ),
    [path, position]
  )
  PanelComponent.displayName = 'PanelComponent'
  PanelComponent.propTypes = PanelComponentPropTypes

  return <PanelComponent {...props} />
}
RoutePanel.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  Component: PropTypes.elementType
}
export default RoutePanel
