import fscreen from 'fscreen'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { block } from 'utils/classnames'

const b = block.with('body')

export const useKeyDown = (handler, targetKeys) => {
  const hasHandler = typeof handler === 'function'
  const callback = useCallback(
    ({ key }) => targetKeys.includes(key) && handler(),
    [handler, targetKeys]
  )
  useEffect(() => {
    hasHandler && window.addEventListener('keydown', callback)
    return () => {
      window.removeEventListener('keydown', callback)
    }
  }, [callback])
}

/**
 * useBodyClassName custom hook
 * @description Handles adding/removing of a CSS class to the page body element
 * @param  {string} className the className to be added to the body element
 */
export const useBodyClassName = (className) => {
  className = typeof className === 'string'
    ? className.trim()
    : null

  const handleClassName = useCallback(
    () => {
      const body = typeof window !== 'undefined'
        ? window.document.body
        : null
      if (body && className) {
        const c = b(className)
          .trim()
          .split(' ')
          .filter(item => !body.classList.contains(item))
        body.classList.add(...c)

        return () => body.classList.remove(...c)
      }
    },
    [className]
  )

  useEffect(handleClassName, [className])
}

/**
 * useFullscreen custom hook
 * @description Handles entering/exiting fullscreen mode
 * @param  {boolean} isActive whether fullscreen mode is active or not
 */
export const useFullscreen = (isActive, onClose) => {
  const fullscreenElement = useRef(null)
  const isAvailable = typeof window !== 'undefined' && fscreen.fullscreenEnabled

  const handleFullscreenChange = useCallback(() => {
    isAvailable && fscreen.fullscreenElement === null && onClose && onClose()
  }, [])

  useMemo(() => {
    if (!isAvailable) {
      isActive && onClose && onClose()
      return
    }

    const isFullscreen = fscreen.fullscreenElement !== null

    if (isActive && !isFullscreen && fullscreenElement.current) {
      fscreen.requestFullscreen(fullscreenElement.current)
      fscreen.addEventListener('fullscreenchange', handleFullscreenChange)
    }

    if (!isActive && isFullscreen) {
      fscreen.exitFullscreen()
      fscreen.removeEventListener('fullscreenchange', handleFullscreenChange)
    }

    return () => {
      if (!isActive && isFullscreen) {
        fscreen.exitFullscreen()
        fscreen.removeEventListener('fullscreenchange', handleFullscreenChange)
      }
    }
  }, [isActive])

  return { isAvailable, fullscreenElement }
}
