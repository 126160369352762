import PropTypes from 'prop-types'
import React from 'react'

import AnimatedOverlay from 'components/Common/AnimatedOverlay'
import AnimatedPanel from 'components/Common/AnimatedPanel'

export const Panel = props => {
  const {
    shouldShow,
    onClose,
    position = 'right',
    children,
    width,
    className,
    canClose = true
  } = props

  const handleClose = () => {
    canClose && onClose && onClose()
  }

  return (
    <>
      <AnimatedOverlay shouldShow={shouldShow} onClick={handleClose} />
      <AnimatedPanel
        position={position}
        shouldShow={shouldShow}
        onClose={onClose}
        width={width}
        className={className}
      >
        {children}
      </AnimatedPanel>
    </>
  )
}
Panel.propTypes = {
  shouldShow: PropTypes.bool,
  onClose: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node
}
export default Panel
