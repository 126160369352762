import PropTypes from 'prop-types'
import React from 'react'
import { useTransition, animated } from 'react-spring'

import { block } from 'utils/classnames'

import './AnimatedPanel.sass'

const b = block.with('animated-panel')

const panelTransitions = {
  right: {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(100%)' }
  },
  left: {
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(-100%)' }
  }
}

export const AnimatedPanel = props => {
  const {
    className = '',
    shouldShow = false,
    position = 'right',
    children,
    width
  } = props

  const transition = panelTransitions[position] || panelTransitions.right
  const transitions = useTransition(shouldShow, null, transition)

  return transitions
    .map(({ item, key, props }) => (
      item
        ? (
          <animated.aside
            key={key}
            className={`${b('', { position })} ${className}`.trim()}
            style={{ ...props, width }}
          >
            {children}
          </animated.aside>
        )
        : null
    ))
}
AnimatedPanel.propTypes = {
  shouldShow: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node
}
export default AnimatedPanel
