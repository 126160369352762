import React, { useCallback } from 'react'

import { useRoute } from 'hooks/router'

export const withRouter = (path) => (Component) => (props) => {
  const {
    hasRoute,
    root,
    query,
    router
  } = useRoute(path)

  const handleClose = useCallback(
    () => {
      const pathname = root === '/' ? root : router.pathname
      router.push(pathname, root, { shallow: true })
    },
    [router.pathname, root]
  )

  return (
    <Component
      shouldShow={hasRoute}
      onClose={handleClose}
      query={query}
      root={root}
      {...props}
    />
  )
}

export default withRouter
