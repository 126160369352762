import * as orderDetailsApi from 'api/orderDetails/orderDetails'
import * as orderTrackingApi from 'api/orderDetails/tracking'

export const getOrderDetails = async hash => {
  try {
    const result = await orderDetailsApi.getOrderDetails(hash)
    return result.data
  } catch (error) {
    return null
  }
}

export const getOrderTrackingData = async hash => {
  try {
    const result = await orderTrackingApi.getOrderTrackingData(hash)
    return result.data
  } catch (error) {
    return null
  }
}
